<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="false"
          title="PO from Requisition"
      />
      <DateQuerySetter @onClickGo="getRequisition" :is-disabled="! filterByDate">
        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
          <br>
          <br>
          <label class="form-check-label" for="send-mail">Filter by date?</label>
          <input class="form-check-input ms-1"
                 id="send-mail" type="checkbox"
                 v-model="filterByDate"
          />
        </div>
        <div class="cols-12 col-sm-6 col-md-4 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Project</label>
          <v-select
              placeholder="Select Project"
              v-model="projectId"
              :options="projects"
              label="name"
              :reduce="name => name.id"
          />
        </div>

        <div class="col-12 col-sm-8 col-md-8 col-lg-3">
          <label for="colFormLabel" class="col-form-label">Requisition Number</label>
          <input class="form-control" type="text" name="requisitionNumber" v-model="requisitionNumber">
        </div>
      </DateQuerySetter>
    </div>

    <ListTable
        :requisition-list="requisitionList"
    />
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>
  </div>
  <GlobalLoader/>
</template>

<script setup>
import TitleButton        from '@/components/atom/TitleButton'
import DateQuerySetter    from '@/components/atom/DateQuerySetter'
import ListTable          from '@/components/molecule/procurement/purchase-order/purchase-order-entry/RequisitionListingTable.vue'
import Pagination         from '@/components/atom/Pagination'
import {
  ref,
  inject,
  onMounted,
  computed
}                         from 'vue'
import {
  useRouter,
  useRoute
}                         from 'vue-router'
import handleContact      from '@/services/modules/contact'
import handleProjects from "@/services/modules/procurement/project";
import handleRequisitions from "@/services/modules/procurement/requisition";
import GlobalLoader from "@/components/atom/GlobalLoader.vue";
import {useStore} from "vuex";

const router  = useRouter()
const route   = useRoute()
const store   = useStore()
const loading = ref(false);

const showError              = inject('showError');
const showSuccess            = inject('showSuccess');
const {fetchContactProfiles} = handleContact()
const {fetchSVRequisitionList}  = handleRequisitions();
const {fetchProjects} = handleProjects()

let page = ref(1);
const offset = ref(10);
const loader = ref(false);
const projects = ref([]);
let projectId = ref(null);
let requisitionList = ref({});
let quick_search = ref(null);
let requester = ref(null);
let requisitionNumber = ref(null);
let companyId = computed(() => route.params.companyId);
let filterByDate = ref(false);

function getQuery() {
  let query = '?company_id=' + companyId.value
  query += '&offset=' + offset.value
  query += '&start_date=' + route.query.start
  query += '&end_date=' + route.query.end
  if(! filterByDate.value) {
    query += '&without_date_filter=' + 1
  }
  if (requester.value) query += '&requester=' + requester.value
  if (requisitionNumber.value) query += '&requisition_number=' + requisitionNumber.value
  if (projectId.value) query += '&project_id=' + projectId.value
  query += '&page=' + (route.query.page ?? page.value)
  return query
}

async function getProjects() {
  try {
    const res = await fetchProjects(getQuery());
    if (res.status) {
      projects.value = res.data;
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

const setPaginationData = (data) => {
  store.commit('setPaginationData', data)
};

async function getRequisition() {

  try {
    const res = await fetchSVRequisitionList(getQuery());
    if (res.status) {
      requisitionList.value = res.data;
      setPaginationData({
        records: res.data.total,
        perPage: res.data.per_page,
        currentPage: res.data.current_page,
      })
    } else {
      requisitionList.value = {};
    }
  } catch (err) {
    if (err.response) {
      showError(err.response.message)
    }
  }
}

function onPageChange(page) {
  let routeQuery  = Object.assign({}, route.query)
  routeQuery.page = page
  router.push({path: route.path, query: routeQuery})
  setTimeout(() => {
    getRequisition();
  }, 100)
}

onMounted(async () => {
  await getProjects();
  await getRequisition();
})
</script>
