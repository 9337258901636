<template>
  <div class="card p-2" style="min-height: 200px">
    <div class="row">
      <div class="col-md-12 text-right mb-2">
        <button :disabled="!selected.length" @click="createPO"
                class="btn btn-primary btn-block waves-effect waves-float waves-light">
          Create PO
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>
            <span v-if="requisitionList.total">
              <input type="checkbox" @click="selectAll" v-model="checkAll">
            </span>
            <span v-else>
              #
            </span>
          </th>
          <th>Requisition Date</th>
          <th>Requisition No</th>
          <th>Requester</th>
          <th>Project</th>
          <th>Department</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in requisitionList.data" :key="i">
          <td>
            <input type="checkbox"
                   :checked="selected.includes(item.id)"
                   @input="check(item.id)"
            >
          </td>
          <td>{{ item.date }}</td>
          <td>{{ item.requisition_number }}</td>
          <td>{{ item.user.name }}</td>
          <td>{{ item.project.name }}</td>
          <td>{{ item.department.name }}</td>
          <td>{{ item.status }}</td>
        </tr>
        </tbody>
      </table>
      <p v-if="!requisitionList.total" class="text-center mt-5">No data</p>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {ref}                 from "vue";

const props  = defineProps({
  requisitionList     : Object,
});
const route  = useRoute();
const router = useRouter();
let selected = ref([]);
let checkAll = ref(false);

function selectAll() {
  selected.value = [];
  if (!checkAll.value) {
    props.requisitionList.data.forEach((item) => {
      selected.value.push(item.id);
    });
  }
}

function check(id) {
  if (selected.value.includes(id)) {
    selected.value.splice(selected.value.indexOf(id), 1);
  } else {
    selected.value.push(id);
  }

  if (selected.value.length === props.requisitionList.data.length) {
    checkAll.value = true;
  } else {
    checkAll.value = false;
  }
}

function createPO() {
  let param = {
    companyId       : route.params.companyId,
    moduleId        : route.params.moduleId,
    menuId          : route.params.menuId,
    pageId          : route.params.pageId,
  }

  let query = route.query;
  query.selected_requisitions = selected.value;

  router.push({
    name  : 'pr-po-add',
    params: param,
    query : query
  })
}
</script>
